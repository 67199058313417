/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { mostrarError, mostrarExito } from "../../../../../core/EmisorMensajes";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { i18nTable } from "../../../../../core/Funciones";
import ImageUploading from 'react-images-uploading';
import EXIF from 'exif-js';
import { v4 as uuid } from "uuid";
import { KTSVG } from "../../../../../_metronic/helpers";
import { calcCrow, createStructureRevision, generate_lat_lang } from "./helper";
import ModalLinkImage from "./ModalLinkImage";
import { FileSelector } from "./FileSelector";
import ModalCreateRevision from "./ModalCreateRevision";
import ModalCreateIssue from "./ModalCreateIssue";
import ModalEditRevision from "./ModalEditRevision";
import { onlyUnique } from "../../../../helper";
import { getIssueCodes, getTowersLine } from "../../../reports/redux/ReportsCRUD";
import { createRevisionPlan, createRevisions, deleteFile, editRevisions, getImagesByRevisionPlan, uploadMultipleFiles } from "../../redux/UploadsCRUD";
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from 'moment'

const CreateUpdateRevisions: React.FC  = () => {

    const [revisionsList, setRevisionsList] = useState([]);
    const [issuesStructureList, setIssuesStructureList] = useState([]);

    const [lineRevision, setLineRevision] = useState<any>(undefined);

    const [structuresList, setStructuresList] = useState([]);
    const [structuresRevisionList, setStructuresRevisionList] = useState([]);

    const [revisionData, setRevisionData] = useState<any>(undefined);

    const [images, setImages] = React.useState([]);

    const [structureSelected, setStructureSelected] = useState<any>();
    const [showStructureImages, setShowStructureImages] = useState(false);

    const [openModalImages, setOpenModalImages] = useState(false);
    const [openModalCreateRevision, setOpenModalCreateRevision] = useState(false);
    const [openModalCreateIssue, setOpenModalCreateIssue] = useState(false);
    const [openModalEditRevision, setOpenModalEditRevision] = useState(false);

    const [loading, setLoading] = useState(false);

    const [creationType, setCreationType] = useState('');

    const [issueCodes, setIssueCodes] = useState([]);

    const [issueSelected, setIssueSelected] = useState<any>();
    const [issueSelectedToEdit, setIssueSelectedToEdit] = useState<any>();

    const { t } = useTranslation();

    useEffect(() => {
        getIssueCodes().then((res: any) => setIssueCodes(res.data.rows));
    }, []);

    useEffect(() => {
        if(revisionsList.length && structuresList.length) {
            const newStructures = structuresList.map(structure => {
                return createStructureRevision(structure, revisionsList);
            })
            setStructuresRevisionList(newStructures);


        }
    }, [revisionsList, structuresList]);

    const handleStructureClick = (structure: string) => {
        if(structure) {
            const issuesStructure = revisionsList.filter(r => r.tower_name === structure && r.issue_number);
            setIssuesStructureList(issuesStructure);
        }
        else {
            setIssuesStructureList([]);
        }
    }

    const onChangeImages = async (imageList) => {
        if(imageList.length) {
            imageList = await Promise.all(imageList.map(async image => {

                let geoData;

                if (image.file) {
                    geoData = await new Promise(resolve =>
                      EXIF.getData(image.file, function() {
                            if (this.exifdata && this.exifdata.GPSLatitude) {
                                const geoData = generate_lat_lang(this);
                                resolve(geoData);
                            } else {
                                resolve({
                                    latitude: '0',
                                    longitude: '0',
                                });
                            }
                        }
                      ));
                }
                else {
                    geoData = {
                        latitude: image.latitude,
                        longitude: image.longitude
                    }
                }

                if (geoData && geoData.latitude && geoData.longitude) {
                    // se calcula la distancia a todas las torres
                    const list = await structuresList.map(structure => {
                        const d = calcCrow(geoData.latitude, geoData.longitude, parseFloat(structure.latitude), parseFloat(structure.longitude));
                        return {
                            ...structure,
                            distance: d
                        }
                    })

                    // se calcula la torre mas cercana, si existe a menos de 50m
                    await list.sort((s1, s2) => s1.distance - s2.distance);
                    {
                        const distance = list[0].distance;
                        if (distance < 0.05) {
                            image.structureCode = list[0].description;
                            image.distance = list[0].distance*1000;
                        }
                    }

                    //set coordenadas
                    image.latitude = geoData.latitude;
                    image.longitude = geoData.longitude;
                } else {
                    console.log("No EXIF data found in image '" + imageList[0].name + "'.");
                    return undefined
                }

                return image;
            }));
            // obtener datos de coordenadas

        }
        const imagesToUpdate = imageList
          .filter(image => image)
          .map(image => ({
              ...image,
              imageId: !image?.imageId ? uuid() : image.imageId,
              name: image.file?.name
          }));
        setImages(imagesToUpdate);
    };

    const RenderImages = (imagesList) => {

        let imagesWithStructure = imagesList.filter(i => i.structureCode);
        const imagesWithoutStructure = imagesList.filter(i => !i.structureCode);
        if (showStructureImages) {
            if (structureSelected) {
                imagesWithStructure = images.filter(image => image.structureCode === structureSelected.description);
            }
            else {
                imagesWithStructure = [];
            }
        }

        return (
          <div className="d-flex flex-column">
              {
                  <div className="d-flex flex-row align-items-center justify-content-start">
                      {/* {
                            !showStructureImages && structureSelected ?  */}
                      <button className="btn btn-sm btn-light-primary"
                              onClick={() => setShowStructureImages(true)}
                              disabled={!showStructureImages || !structureSelected}>
                          Mostrar sólo imágenes de la estructura seleccionada
                      </button>
                      {/* : null */}
                      {/* }
                        {
                            showStructureImages ? */}
                      <button className="btn btn-sm btn-light-info ms-3"
                              onClick={() => setShowStructureImages(false)}>
                          Mostrar todas las imágenes de la revisión
                      </button>
                      {/* : null */}
                      {/* } */}
                  </div>
              }
              <div className="mt-5" style={{ maxHeight: '300px', overflowX: 'auto' }}>
                  <ImageUploading
                    multiple
                    value={imagesList}
                    onChange={(imagesModified) => onChangeImages(imagesModified)}
                    maxNumber={100}
                    dataURLKey="data_url"
                    acceptType={['jpg', 'png', 'wepb', 'heic']}
                  >
                      {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageRemove,
                            isDragging,
                            dragProps,
                        }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <>
                                <button
                                  className="btn btn-success btn-sm ms-3"
                                  style={isDragging ? { color: 'red' } : undefined}
                                  onClick={() => onImageUpload()}
                                  {...dragProps}
                                >
                                    <i className="fa fa-plus"></i>Agregar imágenes a la revision
                                </button>
                                {
                                  showStructureImages &&
                                  <button
                                    className="btn btn-primary btn-sm"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={() => setOpenModalImages(true)}
                                    // disabled={!showStructureImages}
                                    {...dragProps}
                                  >
                                      <i className="fa fa-link"></i>Vincular imágenes a la estructura
                                  </button>
                                }

                                {
                                    imageList && imageList.length && !showStructureImages ?
                                      <button className="btn btn-light-danger btn-sm ms-3" onClick={onImageRemoveAll}>
                                          <i className="fa fa-trash"></i>
                                          Eliminar todas
                                      </button>
                                      : null
                                }
                                <div className="mt-5">
                                    {
                                        showStructureImages && structureSelected && imagesWithStructure.length ?
                                          <h4>{("Imagenes de la estructura " + (structureSelected?.description || ''))}</h4>
                                          :
                                          (imagesWithStructure.length ? <h4>Todas las imagenes de la revision</h4> : null)
                                    }
                                    {
                                        /** Cuando no hay ningun tipo de imagen aun */
                                        !imagesWithoutStructure.length && !imagesWithStructure.length ?
                                          <div className="d-flex flex-column">
                                              <h4>Galería de imágenes</h4>
                                              <p style={{ fontStyle: 'italic' }}>No hay imagenes para mostrar</p>
                                          </div> : null
                                    }
                                </div>
                            </>

                            <div className="mt-5 images-scroll">
                                {imagesWithStructure.map((image, i) => (
                                  <div key={i} className="image-item">
                                      <div className="d-flex flex-column">
                                          <label className="text-muted">
                                              {
                                                  image.distance ?
                                                    ('A ' + parseInt(image.distance) + ' m. de ' + image.structureCode)
                                                    :
                                                    ('Sin vincular')
                                              }
                                          </label>
                                          <img src={image['data_url']} alt="" width="200" height="150" />
                                      </div>
                                      <div className="image-item__btn-wrapper">
                                          <button className="btn btn-icon btn-danger btn-sm" style={{
                                              position: 'relative',
                                              top: '-37px',
                                          }} onClick={() => {
                                              const index = images.findIndex(ii => ii.imageId === image.imageId);
                                              if (index !== -1) {
                                                  const imagesToUpdate = [...images];
                                                  const imageSelected = imagesToUpdate[index];
                                                  imageSelected.structureCode = '';
                                                  imagesToUpdate[index] = imageSelected;
                                                  setImages(imagesToUpdate);
                                                  mostrarExito('La imagen se ha desvinculado correctamente!');
                                              }
                                          }}>
                                              <KTSVG
                                                path='/media/icons/duotune/coding/cod008.svg'
                                                className='svg-icon-1'
                                              />
                                          </button>
                                      </div>
                                  </div>
                                ))}
                            </div>

                            {imagesWithoutStructure.length ?
                              <div className="mt-5 images-scroll">
                                  <h5>Imágenes sin vincular</h5>
                                  {imagesWithoutStructure.map((image, i) => (
                                    <div key={i} className="image-item">
                                        <div className="d-flex flex-column">
                                            <label className="text-muted">
                                                Sin vincular
                                            </label>
                                            <img src={image['data_url']} alt="" width="200" height="150" />
                                        </div>
                                        <div className="image-item__btn-wrapper">
                                            <button className="btn btn-icon btn-danger btn-sm" style={{
                                                position: 'relative',
                                                top: '-37px',
                                            }} onClick={() => {
                                                const index = images.findIndex(ii => ii.imageId === image.imageId);
                                                if (index !== -1) {
                                                    const imageSelected = images[index];
                                                    const imagesToUpdate = [...images];
                                                    imagesToUpdate.splice(index, 1);
                                                    setImages(imagesToUpdate);

                                                    deleteFile(revisionData.revisionPlanId, imageSelected.imageId)
                                                      .then(res => {
                                                          if (res) {
                                                              mostrarExito('La imagen se ha eliminado correctamente!');
                                                          }
                                                      })
                                                }
                                            }}>
                                                <KTSVG
                                                  path='/media/icons/duotune/general/gen027.svg'
                                                  className='svg-icon-1'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                  ))}
                              </div> : null
                            }

                        </div>
                      )}
                  </ImageUploading>
              </div>
          </div>
        )
    }

    const handleOnConfirmCreateRevision = data => {

        setLineRevision(data.lineRevision);
        setStructuresRevisionList(data.structuresRevisionList);
        setStructuresList(data.structuresList);

        const revData = {
            rev_date: data.startDate,
            rev_number: data.numberRevision,
            type: 'WEB'
        }
        setRevisionData(revData);

        const revisionList = [];
        data.structuresRevisionList.forEach(structure => {
            revisionList.push({
                tower_name: structure.description,
                rev_number: data.numberRevision,
                rev_date: data.startDate,
                line_code: data.lineRevision.code
            });
        })
        setRevisionsList(revisionList);

        setOpenModalCreateRevision(false);
    };

    const handleOnConfirmCreateIssue = data => {

        setIssueSelectedToEdit(undefined);

        //se busca si hay alguna incidencia con el mismo numero
        // const index = issuesStructureList.findIndex(i => !i.issue_id && i.issue_number === data?.issueNumber);
        // if (index !== -1) {
        //     mostrarWarning('Ya existe una incidencia con el número ' + data?.issueNumber);
        //     return;
        // }

        const revisions = revisionsList.filter(r => r.tower_name === structureSelected?.description);
        if (revisions.length) {
            const revision = revisions[0];
            // set issue data
            const issueStructure = {
                ...revision,
                issue_id: data?.issue_id,
                issue_code_id: data?.issueCode?.id,
                issue_code: data?.issueCode?.code_name,
                issue_description: data?.issueCode?.description,
                issue_number: data?.issueNumber,
                line_code: lineRevision?.code,
                tower_name: structureSelected?.description,
                level_risk: data?.issueCode?.level_risk,
                issue_status: data?.status,
                repair_date: data?.resolutionDate || null,
                observations: data?.observations || '',
                resolution_comment: data.resolution_comment || '',
            }
            if (!data?.issue_id || data?.issue_id === '') {
                issueStructure.issue_id = '';
                issuesStructureList.push(issueStructure);
            } else {
                const index = issuesStructureList.findIndex(i => i.issue_number === data?.issueNumber);
                if (index !== -1) {
                    issueStructure.issue_id = data?.issue_id !== 'edit' ? data?.issue_id : '';
                    issuesStructureList[index] = issueStructure;
                }
            }

            // create list of issues
            const revList = [...revisionsList];
            const issuesStructure = revisionsList.filter(rr => rr.tower_name === structureSelected.description);
            if (issueStructure.issue_id) {
                // si la incidencia existe porque fue creada previamente, se debe editar
                const index = revisionsList.findIndex(ii => ii.issue_id === issueStructure.issue_id);
                revList[index] = {...issueStructure};
                setRevisionsList(revList);
            }
            else if(issuesStructure.length > 1 || (issuesStructure.length === 1 && issuesStructure[0].issue_number && issuesStructure[0].issue_number !== issueStructure.issue_number)) {
                // si ya habia incidencias, la agrego a la lista para que sea una mas
                revisionsList.push({
                    ...issueStructure
                });
            }
            else {
                // si no habia incidencia antes, reemplazo el item 'sin incidencias' por la nueva
                const index = revisionsList.findIndex(rr => rr.tower_name === structureSelected.description);
                revList[index] = {...issueStructure};
                setRevisionsList(revList);
            }

            // update status
            const index = structuresRevisionList.findIndex(rr => rr.tower_name === structureSelected.description);
            const structuresRev = [...structuresRevisionList];
            structuresRev[index] = createStructureRevision(structureSelected, revList, revisionData?.revDate);
            setStructuresRevisionList(structuresRev);

            setOpenModalCreateIssue(false);
            setIssueSelected(undefined);
        } else {
            mostrarError("No se pudo identificar la revisión")
        }

    };

    const handleOnConfirmEditRevision= async (data, line, revisionPlanId) => {

        // map data values revisions

        const dataRevs = [];
        data.forEach((rev: any) => {
            let dataRevItem;
            if (rev.issues && rev.issues.length) {
                rev.issues.forEach(issue => {
                    dataRevItem = {
                        ...rev,
                        ...issue,
                        issue_id: issue.id,
                        issue_number: issue.number,
                        rev_date: rev.date,
                        rev_number: rev.number,
                    };

                    dataRevItem.revision_id = rev.id;
                    dataRevItem.tower_name = rev?.towerLine?.tower?.description;

                    const issueCode = issueCodes.filter(ic => ic.id == dataRevItem.issue_code);
                    if (issueCode.length) {
                        dataRevItem.issue_code = issueCode[0].code_name;
                        dataRevItem.issue_description = issueCode[0].description;
                    }

                    dataRevs.push(dataRevItem);
                })
            } else {
                dataRevItem = {
                    ...rev,
                    rev_date: rev.date,
                    rev_number: rev.number
                };

                dataRevItem.revision_id = rev.id;
                dataRevItem.tower_name = rev?.towerLine?.tower?.description;

                const issueCode = issueCodes.filter(ic => ic.id == dataRevItem.issue_code);
                if (issueCode.length) {
                    dataRevItem.issue_code = issueCode[0].code_name;
                    dataRevItem.issue_description = issueCode[0].description;
                }

                dataRevs.push(dataRevItem);
            }

        })
        const revDetailsFile = dataRevs.map((row: any) => ({ rev_date: row.date, rev_number: row.number }));
        const revDetails = onlyUnique(revDetailsFile);

        let revData;
        if (revDetails.length !== 1) {
            console.error('mas de una revision');
            return;
        }
        else {
            revData = {
                ...revDetails[0],
                type: 'WEB',
                revisionPlanId,
            }
            setRevisionData(revData);
        }

        setLineRevision(line);
        setRevisionsList(dataRevs);

        getTowersLine({ line_id: line.id })
          .then((res: any) => {
              //se ordenan las torres por su orden en la linea
              let towersData = res.data;
              towersData = towersData.map((tower, index) => {
                  const result = {
                      ...tower,
                      order: index
                  };
                  const towerLine = tower.lines.filter(tl => tl.line_id === line.id);
                  if (towerLine.length) {
                      result.order = towerLine[0].order;
                  }
                  return result;
              })
              towersData = towersData.sort((t1, t2) => t1.order - t2.order);
              setStructuresList(towersData);
          });

        // se cargan las imagenes
        const imagenesRevision = await getImagesByRevisionPlan(revData.revisionPlanId).then(res => res.data);
        imagenesRevision.forEach(imagenRevision => {

            if (imagenRevision.latitude && imagenRevision.longitude && imagenRevision.tower) {
                let d = calcCrow(imagenRevision.latitude, imagenRevision.longitude, parseFloat(imagenRevision.tower.latitude), parseFloat(imagenRevision.tower.longitude));
                const imageData = {
                    data_url: imagenRevision.multimediaPath,
                    latitude: imagenRevision.latitude,
                    longitude: imagenRevision.longitude,
                    structureCode: imagenRevision.towerName,
                    distance: d,
                    uploaded: true,
                    name: imagenRevision.filename,
                    imageId: imagenRevision.imageId,
                }
                images.push(imageData);
            } else {
                const imageData = {
                    data_url: imagenRevision.multimediaPath,
                    latitude: imagenRevision.latitude,
                    longitude: imagenRevision.longitude,
                    structureCode: imagenRevision.towerName,
                    uploaded: true,
                    name: imagenRevision.filename,
                    imageId: imagenRevision.imageId,
                }
                images.push(imageData);
            }

        })

        setOpenModalEditRevision(false);
    };


    const handleSave = async () => {
        setLoading(true);

        let line_code = '';
        let id_revision_randon=0;

        const revisionsData = revisionsList.map(r => {
            id_revision_randon= r.id_revision_randon?r.id_revision_randon:0;
            const result = { ...r };
            const structure = structuresRevisionList.find(sr => sr.description === r.tower_name);
            if (typeof result.rev_date === 'string' && result.rev_date.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                // const parts = result.rev_date.split('/');
              const fechaMoment = moment(result.rev_date, 'DD/MM/YYYY');

              const fechaFormateada = fechaMoment.format('YYYY-MM-DD HH:mm:ss');
                // result.rev_date = moment(`${parts[2]}-${parts[1]}-${parts[0]}T00:00:00.001Z`).toISOString();
                result.rev_date = fechaFormateada;

            }
            if (structure) {
                const status = structure.status_code;
                result.status = status;
                // result.id_revision_randon = structure.id_revision_randon;
                result.line_code = String(result.line_code);
                result.line_name = String(result.line_name);
                line_code = result.line_code;
            }
            return result;
        });

        try {
            let revisionPlanId;
            if (creationType !== 'edit') {
                const body = {
                    line_id:lineRevision.id,
                    origen:'WEB',
                    id_revision_randon:id_revision_randon,

                };
                const revisionPlanResponse = await createRevisionPlan(body);
                if (revisionPlanResponse.status === 400) {
                    throw revisionPlanResponse.data;
                    // mostrarError(revisionPlanResponse.data.message);
                    // return; // Detener la ejecución del método
                }
                // mostrarError(resultImages.data.message);
                revisionPlanId = revisionPlanResponse.data.id;
            } else {
                revisionPlanId = revisionData.revisionPlanId;
            }

            const body = {
                revision_plan_id: revisionPlanId,
                revisions: revisionsData,
            };

            let result;
            if (creationType !== 'edit') {
                result = await createRevisions(body);
            } else {
                result = await editRevisions(body);
            }

            if (result.status === 200) {
                mostrarExito('Se han guardado los datos correctamente');
                if(images && images.length){
                    // Upload de imágenes
                    let imagesToUpload = images.map(image => {
                        let imageUpdated = image;
                        if (!image.uploaded) {
                            imageUpdated = {
                                ...image,
                                name: `${revisionPlanId}/${image.name}`
                            };
                        }
                        return imageUpdated;
                    });
                    const bodyImages = {
                        images: imagesToUpload,
                        revisionPlanId: revisionPlanId
                    };
                    const resultImages = await uploadMultipleFiles(bodyImages);
                    if (resultImages.status === 200) {
                        mostrarExito('Se han guardado las imágenes');
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    } else {
                        mostrarError(resultImages.data.message);
                    }
                }else{
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                }

            } else {
                mostrarError(result.data.message);
            }
        } catch (error) {

            if (error.response && error.response.data) { // Verifica si es un error de respuesta
                mostrarError(error.response.data.message); // Muestra el mensaje de error específico de la respuesta
            } else {
                mostrarError('Se produjo un error al crear el plan de revisión: ' + error.message); // Muestra un mensaje de error general
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSave2 = async () => {
        setLoading(true);

        let line_code ='';
        let rev_date ='';

        const revisionsData = revisionsList.map(r => {
            const result = {...r};
            const structure = structuresRevisionList.filter(sr => sr.description === r.tower_name);

            if (structure.length) {
                const status = structure[0].status_code;
                result.status = status;
                result.line_code = String(result.line_code);
                result.line_name = String(result.line_name);
                line_code = result.line_code;
                rev_date = result.rev_date;
            }
            return result;
        });

        // guardado de datos de revisiones e incidencias
        if (creationType !== 'edit') {
            const body2 = {
                line_id:lineRevision.id,
                origen:'WEB',

            };
            const revisionPlan: any = await createRevisionPlan(body2).then(res => res.data);

            const body3 = {
                revision_plan_id: revisionPlan.id,
                revisions: revisionsData,
            };

            const result = await createRevisions(body3).then(res => res.data);
            if (result.message === 'OK') {
                mostrarExito('Se han guardado los datos correctamente');
            } else {
                mostrarError(result.message);
            }
        }
        else {
            const body = {
                revision_plan_id: revisionData.revisionPlanId,
                revisions: revisionsData,
            };
            const result = await editRevisions(body).then(res => res.data);
            if (result.message === 'OK') {
                mostrarExito('Se han guardado los datos de la revisión');
                setLoading(false);
            } else {
                mostrarError(result.message);
                setLoading(false);
            }
        }
        console.log(revisionData.revisionPlanId)
        setLoading(true);
        // upload de imagenes
        // se le agrega la carpeta de la revision a todas aquellas imagenes que no la tengan
        let imagesToUpload = images
          .map(image => {
              let imageUpdated = image;
              if (!image.uploaded) {
                  imageUpdated = {
                      ...image,
                      name: `${revisionData.revisionPlanId}/${image.name}`
                  };
              }
              return imageUpdated;
          });
        const bodyImages = {
            images: imagesToUpload,
            revisionPlanId: revisionData.revisionPlanId
        };
        const resultImages = await uploadMultipleFiles(bodyImages).then(res => res.data);
        if (resultImages.message === 'OK') {
            mostrarExito('Se han guardado las imágenes');
            setLoading(false);

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            mostrarError(resultImages.message);
            setLoading(false);
        }
    }

    return (
      <LoadingOverlay active={loading} spinner text={'Guardando cambios...'}>
          <Card>
              <Card.Header>
                  <Card.Title style={{ width: '100%' }}>
                      <strong className="title-upload w-100">{t('uploadRevisions')}</strong>
                      <div className="w-100">
                          <button disabled={loading} style={{float: 'right'}} className="btn btn-success btn-sm" onClick={() => handleSave()}>
                              {loading && (
                                <span className='indicator-progress' style={{display: 'inline'}}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2 me-2'></span>
                                    {' '}
                                    </span>
                              )}
                              Guardar cambios
                          </button>
                          <button style={{float: 'right'}} className="btn btn-outline-danger btn-sm" onClick={() => window.location.reload()}>
                              Volver al inicio
                          </button>
                      </div>
                  </Card.Title>
              </Card.Header>
              <Card.Body>
                  {
                      creationType === '' ?
                        <div className="d-flex flex-row justify-content-center">
                            <button className="btn btn-primary me-5" onClick={() => setCreationType('file')}>Crear revisión desde un fichero</button>
                            <button className="btn btn-primary me-5" onClick={() => {
                                setCreationType('zero');
                                setOpenModalCreateRevision(true);
                            }}>Crear revisión desde cero</button>
                            <button className="btn btn-primary" onClick={() => {
                                setCreationType('edit');
                                setOpenModalEditRevision(true);
                            }}>Editar revisión existente</button>
                        </div>
                        : null
                  }
                  {
                      creationType === 'file' ?
                        <FileSelector
                          onChangeRevisionData={(data: any[]) => setRevisionData(data)}
                          onChangeStructureRevisionList={(data: any[]) => setStructuresRevisionList(data)}
                          onChangeRevisionList={(data: any[]) => setRevisionsList(data)}
                          onChangeStructureList={(data: any[]) => setStructuresList(data)}
                          onChangeLineRevision={(data: any[]) => setLineRevision(data)}
                        />
                        : null
                  }

                  {
                      revisionData ?
                        <div className="row mt-5">
                            <div className="col-lg-3">
                                <div className="revision-details">
                                    <p><strong>Revision N°: </strong> {revisionData?.rev_number}</p>
                                    <p><strong>ID Excel°: </strong> {revisionData?.idRevisionRandon}</p>
                                    <p><strong>Línea: </strong>{lineRevision?.name}</p>
                                    <p><strong>Fecha: </strong> {revisionData?.rev_date}</p>
                                    <p><strong>Tipo de revisión: </strong> {revisionData?.type}</p>
                                </div>
                                <div className="mt-5">
                                    <ReactDataGrid
                                      idProperty="id"
                                      style={{ minHeight: 150, height: '50vh', overflowY: 'auto' }}
                                      columns={[
                                          { name: "order", header: '', maxWidth: 40 },
                                          { name: "description", header: t('Código'), defaultFlex: 1 },
                                          { name: "date", header: t('date'), defaultFlex: 1 },
                                          { name: "status", header: t('Estado'), defaultFlex: 1 },
                                      ]}
                                      dataSource={structuresRevisionList}
                                      onRowClick={e => handleStructureClick(e.data.description)}
                                      i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                                      enableSelection
                                      multiSelect={false}
                                      checkboxColumn
                                      onSelectionChange={(selection: any) => {
                                          const { selected, data } = selection;
                                          if(selected) {
                                              handleStructureClick(data.description);
                                              setStructureSelected(data);
                                              setShowStructureImages(true);
                                          }
                                          else {
                                              handleStructureClick('');
                                              setStructureSelected(null);
                                              setShowStructureImages(false);
                                          }
                                      }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="issues-list">
                                    {
                                        structureSelected ?
                                          <div className="d-flex flex-row justify-content-end">
                                              <button className="btn btn-sm btn-primary" onClick={() => {
                                                  setIssueSelectedToEdit(undefined);
                                                  setOpenModalCreateIssue(true);
                                              }}>Agregar incidencia</button>
                                          </div>
                                          : null
                                    }

                                    <ReactDataGrid
                                      idProperty="id"
                                      style={{ minHeight: 150, height: '20vh', overflowY: 'auto' }}
                                      columns={[
                                          { name: "issue_number", header: t('Nro.'), width: 75 },
                                          { name: "issue_code", header: t('Tipo'), width: 80 },
                                          { name: "issue_description", header: t('Descripción'), defaultFlex: 1 },
                                          { name: "issue_status", header: t('Estado'), defaultFlex: 1,
                                              render: ({data}) =>
                                                <>
                                                    {t(data.issue_status)}
                                                </>
                                          },
                                          { name: "observations", header: t('Observaciones'), defaultFlex: 1 },
                                          { name: "repair_date", header: t('Fecha rep.'), width: 120 },
                                          { name: "resolution_comment", header: t('resolutionObs'), defaultFlex: 1 },
                                          { name: "actions", header: '', width: 60,
                                              render: ({ data }) =>
                                                <div className="p-0 text-center">
                                                    <button className="btn btn-sm" onClick={() => {
                                                        setIssueSelectedToEdit({
                                                            ...data,
                                                            issue_id: data.issue_id || 'edit',
                                                        });
                                                        setOpenModalCreateIssue(true);
                                                    }}>
                                                        <i className="fa fa-edit text-warning"></i>
                                                    </button>
                                                </div>
                                          },
                                      ]}
                                      dataSource={issuesStructureList}
                                      i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                                    />
                                </div>
                                <div className="images-list mt-5 row">
                                    <div className="col-lg-12">
                                        {RenderImages(images)}
                                    </div>
                                    <ModalLinkImage
                                      open={openModalImages}
                                      images={images}
                                      structureSelected={structureSelected}
                                      onUpdateImages={(images) => setImages(images)}
                                      onClose={() => setOpenModalImages(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                  }
              </Card.Body>
          </Card>
          <ModalCreateRevision
            open={openModalCreateRevision}
            onClose={() => setOpenModalCreateRevision(false)}
            onConfirm={(data: any) => handleOnConfirmCreateRevision(data)}
          />
          <ModalCreateIssue
            open={openModalCreateIssue}
            onClose={() => {
                setIssueSelectedToEdit(undefined);
                setOpenModalCreateIssue(false);
            }}
            onConfirm={(data: any) => handleOnConfirmCreateIssue(data)}
            data={issueSelectedToEdit || { issue_number: (issuesStructureList.length + 1) }}
            issueCodes={issueCodes}
          />
          <ModalEditRevision
            open={openModalEditRevision}
            onClose={() => setOpenModalEditRevision(false)}
            onConfirm={(data: any, line: any, revisionPlanId: any) => handleOnConfirmEditRevision(data, line, revisionPlanId)}
          />
      </LoadingOverlay>
    );
}

export { CreateUpdateRevisions };
