/* eslint-disable eqeqeq */
import moment from "moment";

export const generate_lat_lang = (imageData: any) => {
    const result = {
        latitude: 0,
        longitude: 0,
    };
    if(imageData && imageData.exifdata && imageData.exifdata.GPSLatitude) {
        //geting cordinates of latitude
        var latDegree = imageData.exifdata.GPSLatitude[0].numerator;
        var latMinute = imageData.exifdata.GPSLatitude[1].numerator;
        var latSecond = imageData.exifdata.GPSLatitude[2].numerator/imageData.exifdata.GPSLatitude[2].denominator;
        result.latitude = -(latDegree + (latMinute/60) + (latSecond/3600)).toFixed(8);
        //geting cordinates of longitude
        var lonDegree = imageData.exifdata.GPSLongitude[0].numerator;
        var lonMinute = imageData.exifdata.GPSLongitude[1].numerator;
        var lonSecond = imageData.exifdata.GPSLongitude[2].numerator/imageData.exifdata.GPSLongitude[2].denominator;
        result.longitude = -(lonDegree + (lonMinute/60) + (lonSecond/3600)).toFixed(8);
    }
    return result;
}

export const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number)  => 
{
//   var R = 6371; // km
//   var dLat = toRad(lat2-lat1);
//   var dLon = toRad(lon2-lon1);
//   var lat1 = toRad(lat1);
//   var lat2 = toRad(lat2);

//   var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
//     Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
//   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
//   var d = R * c;
//   return d;
    const unit = 'K';
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        // if (unit=="N") { dist = dist * 0.8684 }
        dist = Math.round(dist);
        return dist;
    }
};

export const createStructureRevision = (structure, revisionsList, startDate?) => {
    const revsStructure = revisionsList.filter(issue => issue.tower_name === structure.description);
    const issues = revsStructure.filter(r => r.issue_number);

    // cantidad de incidencias y fecha de revision
    if(!revsStructure.length) {
        structure.status = 'Sin rev.';
        structure.status_code = 'NO_REVISION';
        structure.date = '';
    }
    //       structure.date = moment(revsStructure[0].date).format('DD/MM/YYYY');
    else if(issues.length) {
        structure.status = issues.length + ' Inc.';
        structure.status_code = 'ISSUE';
        console.log(revsStructure[0].date);
        structure.date= moment(revsStructure[0].date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        // structure.date = moment(revsStructure[0].date).format('DD/MM/YYYY');
        console.log(revsStructure[0].date);
        structure.count_revisions = issues.length;
    }
    else {
        structure.status = 'Sin inc.';
        structure.status_code = 'NORMAL';
        if (revsStructure.length && revsStructure[0].date) {
            structure.date = moment(revsStructure[0].date, 'DD/MM/YYYY').format('DD/MM/YYYY');
            // structure.date = moment(revsStructure[0].date).format('DD/MM/YYYY');
        }
        else {
            // structure.date = moment(startDate).format('DD/MM/YYYY');
            structure.date = moment(startDate, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }
        structure.count_revisions = 0;
    }

    return structure;
}
